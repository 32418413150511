@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-light .inverted {
  @apply bg-themes-light-invertedBackground text-themes-light-invertedText;
}

.theme-dark .inverted {
  @apply bg-themes-dark-invertedBackground text-themes-dark-invertedText;
}

:root {
  --background-color: #FFEBCD;
  --inverted-background-color: #2D2D2D;
  --text-color: #2D2D2D;
  --inverted-text-color: #FFEBCD;
  --primary-color: #9c73fc;
  --secondary-color: #dc0073;
  --success-color: #04e762;
  --information-color: #008bf8;
  --error-color: #dc0073;
  --warning-color: #f5b700;
  --white-color: #FFFFFF;
}

.theme-dark {
  --background-color: #1E1E1E;
  --inverted-background-color: #FFEBCD;
  --text-color: #FFEBCD;
  --inverted-text-color: #1E1E1E;
  --primary-color: #9c73fc;
  --secondary-color: #dc0073;
  --success-color: #04e762;
  --information-color: #008bf8;
  --error-color: #dc0073;
  --warning-color: #f5b700;
  --white-color: #FFFFFF;
}

.text-white {
  color: var(--white-color);
}

.text-body {
  color: var(--text-color);
}

.text-inverted {
  color: var(--inverted-text-color);
}

.text-primary {
  color: var(--primary-color);
}

.text-success {
  color: var(--success-color);
}

.text-information {
  color: var(--information-color);
}

.text-error {
  color: var(--error-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.bg-body {
  background-color: var(--background-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-success {
  background-color: var(--success-color);
}

.bg-information {
  background-color: var(--information-color);
}

.bg-error {
  background-color: var(--error-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.text-black {
  color: #121212;
}

.text-poetsen {
  font-family: "Poetsen One", cursive;
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: 'Tilt Neon','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scrollbar for Webkit browsers */
.slim-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.slim-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

.slim-scrollbar::-webkit-scrollbar-thumb {
  background: var(--inverted-text-color); /* Thumb color */
}

.slim-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Custom scrollbar for Firefox */
.slim-scrollbar {
  scrollbar-width: thin; /* 'thin' or can use 'auto' or a specific width like '8px' */
  scrollbar-color: var(--inverted-text-color) #f1f1f1; /* Thumb and track color */
}

.markdown-content a {
  text-decoration: underline;
}

.markdown-content hr {
  margin: 1.2em 0;
}

.bg-black-main {
  background-color: #121212;
}

.border-primary {
  border-color: var(--primary-color);
};

.border-secondary {
  border-color: var(--secondary-color);
};

@keyframes talk {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.talking {
  animation: talk 0.4s ease-in-out infinite;
}

.merch-display {
  transition: opacity 300ms ease-in-out;
}

.merch-display.hidden {
  opacity: 0;
}

.merch-display.visible {
  opacity: 1;
}

.spinner {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0%, 25%, 100% {
      transform: translateX(0);
  }
  10%, 20% {
      transform: translateX(-10px);
  }
  15% {
      transform: translateX(10px);
  }
}

.markdown-content {
  word-wrap: break-word;
  width: 100%; /* Ensure content takes full width of container */
}

.markdown-content p {
  margin-bottom: 1rem;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Poetsen One', cursive;
}

.markdown-content h1 {
  font-size: 2.5rem;
}

.markdown-content h2 {
  font-size: 2rem;
}

.markdown-content h3 {
  font-size: 1.75rem;
}

.markdown-content h4 {
  font-size: 1.5rem;
}

.markdown-content h5 {
  font-size: 1.25rem;
}

.markdown-content h6 {
  font-size: 1rem;
}

.markdown-content strong {
  font-weight: 700;
}

.markdown-content em {
  font-style: italic;
}

/* Lists */
.markdown-content ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.markdown-content ol {
  list-style-type: decimal;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.markdown-content li {
  margin-bottom: 0.5rem;
}

/* Blockquotes */
.markdown-content blockquote {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border-left: 4px solid #ccc;
  background-color: var(--text-color);
  color: #555;
}

/* Code blocks */
.markdown-content pre {
  background: var(--text-color);
  color: var(--inverted-text-color);
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
  word-wrap: break-word;
  margin-bottom: 1rem;
  white-space: pre-wrap; /* Allow line breaks in long code lines */
}

.markdown-content code {
  background: var(--text-color);
  color: var(--inverted-text-color);
  padding: 0.2rem 0.4rem;
  border-radius: 3px;
  overflow-x: auto;
  white-space: pre-wrap; /* Allow line breaks in inline code */
}

/* Horizontal Rules */
.markdown-content hr {
  border: 0;
  border-top: 1px solid var(--primary-color);
  margin: 2rem 0;
}

/* Links */
.markdown-content a {
  color: var(--text-color);
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

pre {
  overflow-x: auto;
}

button {
  font-family: 'Poetsen One', cursive;
}

.markdown-content img {
  max-width: 300px;
  height: auto;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shake {
  animation: shake 5s ease-in-out; /* Shake duration */
  animation-iteration-count: infinite;
  animation-delay: 5s; /* Time before the animation starts */
}

@keyframes pulse {
  0% {
      transform: scale(1);
      opacity: 0.9;
  }
  50% {
      transform: scale(1.1);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0.9;
  }
}

.pulse {
  animation: pulse 2s infinite;
}

.shimmer {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Poetsen One', cursive;
  background: linear-gradient(90deg, #3a0250, #bea3ff, #3a0250);
  letter-spacing: 5px;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shine 3s linear infinite;
  position: relative;
}

@keyframes shine {
  0% {
    background-position-x: -300%;
  }
  100% {
    background-position-x: 300%;
  }
}